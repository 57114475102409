import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import "../styles/accordion.css";

const IOTAccordion = () => {
  return (
    <div className="p-8">
      {/* Oil Gas and Energy */}
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full h-14 items-center text-sm font-medium text-left text-bluegray focus:outline-none border-b border-white border-opacity-50`}
            >
              <span>Oil, Gas and Energy</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180 text-white" : ""
                } w-5 h-5 text-bluegray`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-6 text-sm text-gray-500">
              <p className="leading-6 text-bluegray">
                Overall Equipment Effectiveness & Health, Asset & Manpower
                tracking, HSE Solutions, Tank Pressure, Volume & Environmental
                sensors
              </p>
              <StaticImage
                alt="katsana"
                src="../../static/images/graphics/iot1.png"
                objectFit="contain"
                className="mt-4 "
              />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {/* Container Ports and Marine yards */}
      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full h-14 items-center text-sm font-medium text-left text-bluegray focus:outline-none border-b border-white border-opacity-50`}
            >
              <span>Container Ports and Marine Yards</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180 text-white" : ""
                } w-5 h-5 text-bluegray`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-6">
              <p className="leading-6 text-bluegray">
                Port access control with driver identification, container
                identification & movement management, fuel access and
                authorization
              </p>
              <StaticImage
                alt="katsana"
                src="../../static/images/graphics/iot2.png"
                objectFit="contain"
                className="mt-4 "
              />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {/* Agriculture, Estates and Plantations */}
      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white " : ""
              } flex justify-between w-full h-14 items-center text-sm font-medium text-left text-bluegray focus:outline-none border-b border-white border-opacity-50`}
            >
              <span>Agriculture, Estates and Plantations</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180 text-white" : ""
                } w-5 h-5 text-bluegray`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-6">
              <p className="leading-6 text-bluegray">
                Smart automation, Skid tank monitoring system, Vehicle Cargo
                weight sensor & management
              </p>
              <StaticImage
                alt="katsana"
                src="../../static/images/graphics/iot3.png"
                objectFit="contain"
                className="mt-4 "
              />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {/* Public Works, Utilities & Telecommunications */}
      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white " : ""
              } flex justify-between w-full h-14 items-center text-sm font-medium text-left text-bluegray focus:outline-none `}
            >
              <span>Public Works, Utilities & Telecommunications</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180 text-white" : ""
                } w-5 h-5 text-bluegray`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-6">
              <p className="leading-6 text-bluegray">
                Environmental sensor solutions, weather stations, flood sensors
                and dashboard
              </p>
              <StaticImage
                alt="katsana"
                src="../../static/images/graphics/iot4.png"
                objectFit="contain"
                className="mt-4 "
              />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );
};

export default IOTAccordion;
