import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { StaticImage } from "gatsby-plugin-image";

const IotTab = () => {
  return (
    <div className="max-w-6xl px-8 py-16 mx-auto md:px-0">
      <Tabs
        className="grid grid-cols-2 gap-12 cursor-pointer"
        selectedTabClassName="bg-[hsla(210,44%,16%,1)] rounded border-l-2 border-blue-800"
        forceRenderTabPanel
      >
        <div className="relative z-10 col-span-1">
          <h3 className="text-4xl font-bold text-white">
            Real-time Operational Visibility
          </h3>
          <p className="mt-8 text-bluegray">
            KATSANA Orbital platform empower industries to gain real-time
            operational visibility and domain awareness.
            <br />
            <br />
            This is achieved by connecting existing legacy industrial sensors
            that are already running in your plants, factories, ports and
            plantations to the KATSANA Orbital platform.
            <br />
            <br />
            New modern sensors and instruments are also strategically installed
            and activated to collect real-time data and generate better insights
            to assist you in making data-informed decisions.
          </p>
          <TabList className="flex flex-col mt-4 text-bluegray">
            <Tab className="py-[1rem] px-[4rem] mt-4 transition-transform duration-700 transform hover:-translate-y-1">
              <h5 id="oil-gas" className="font-medium text-white">
                Oil, Gas and Energy
              </h5>
              <p className="text-sm">
                Overall Equipment Effectiveness & Health, Asset & Manpower
                tracking, HSE Solutions, Tank Pressure, Volume & Environmental
                sensors
              </p>
            </Tab>
            <Tab className="py-[1rem] px-[4rem] transition-transform duration-700 transform hover:-translate-y-1">
              <h5 id="seaports" className="font-medium text-white">
                Container Ports and Marine Yards
              </h5>
              <p className="text-sm">
                Port access control with driver identification, container
                identification & movement management, fuel access and
                authorization
              </p>
            </Tab>
            <Tab className="py-[1rem] px-[4rem] transition-transform duration-700 transform hover:-translate-y-1">
              <h5 id="agriculture" className="font-medium text-white">
                Agriculture, Estates and Plantations
              </h5>
              <p className="text-sm">
                Smart automation, Skid tank monitoring system, Vehicle Cargo
                weight sensor & management
              </p>
            </Tab>
            <Tab className="py-[1rem] px-[4rem] transition-transform duration-700 transform hover:-translate-y-1">
              <h5 id="public-works" className="font-medium text-white">
                Public Works, Utilities & Telecommunications
              </h5>
              <p className="text-sm">
                Environmental sensor solutions, weather stations, flood sensors
                and dashboard
              </p>
            </Tab>
          </TabList>
        </div>
        <div className="col-span-1">
          <TabPanel>
            <StaticImage
              alt="katsana"
              src="../../static/images/graphics/iot1.png"
              objectFit="contain"
              className=" w-[800px]"
            />
          </TabPanel>
          <TabPanel>
            <StaticImage
              alt="katsana"
              src="../../static/images/graphics/iot2.png"
              objectFit="contain"
              className=" w-[800px]"
            />
          </TabPanel>
          <TabPanel>
            <StaticImage
              alt="katsana"
              src="../../static/images/graphics/iot3.png"
              objectFit="contain"
              className=" w-[800px]"
            />
          </TabPanel>
          <TabPanel>
            <StaticImage
              alt="katsana"
              src="../../static/images/graphics/iot4.png"
              objectFit="contain"
              className=" w-[800px]"
            />
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default IotTab;
