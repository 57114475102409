import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Cta from "../components/cta";
import IotTab from "../components/iottab";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import * as Styles from "../styles/effect.module.css";
import IOTAccordion from "../components/iotaccordion";

const IOTPage = () => {
  const breakpoints = useBreakpoint();
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>KATSANA® Integrated Operation Solutions</title>
        <meta
          name="description"
          content="Transforming Industrial Operations for the Digital Age. Connecting sensors to the cloud for real-time visibility."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.katsana.com/industrial-iot/" />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.katsana.com/industrial-iot/"
        />
        <meta
          property="og:title"
          content="KATSANA® Integrated Operation Solutions"
        />
        <meta
          property="og:description"
          content="Transforming Industrial Operations for the Digital Age. Connecting sensors to the cloud for real-time visibility."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large_industrial.jpg"
        />
        <meta
          property="og:image:alt"
          content="KATSANA® Integrated Operation Solutions"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        {/* image must be 2:1 max 5mb */}
        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large_industrial.jpg"
        />
      </Helmet>
      {/* Hero Area */}
      <div className="relative p-8 pb-20 overflow-hidden md:p-0 md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/iot-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative z-10 max-w-6xl mx-auto md:text-center">
          <h3 className="max-w-[200px] pt-24 md:pt-12 md:mx-auto text-sm text-bluegray md:text-base md:max-w-none">
            Connecting Legacy Industrial Sensors to the Cloud
          </h3>
          <h1 className="max-w-5xl pt-4 mx-auto text-4xl font-bold tracking-wide text-white md:pt-2 md:leading-tight md:mt-0 md:text-6xl">
            Transforming Industrial Operations for the Digital Age
          </h1>
          <p className="max-w-2xl mt-12 text-sm leading-6 tracking-wide md:mx-auto md:text-center md:mt-8 text-bluegray md:leading-9 md:text-xl">
            ​KATSANA® provides industrial operations with real-time visibility
            and automated day-to-day reporting for the digital age. Take the
            leap into future of industrial operations by connecting existing and
            new sensors to KATSANA Orbital Platform.
          </p>

          <div className="justify-start max-w-xl mx-auto mt-12 md:flex md:items-center md:justify-between md:mt-20">
            <a
              href="https://apps.katsana.com/get-quotation/"
              className="inline px-5 py-2 font-semibold text-white rounded-full md:flex md:self-start md:px-6 md:py-3 md:text-base leading-0 bg-primary"
            >
              Request Consultation
            </a>
            <a
              href="tel:+60377334474"
              className="block mt-4 font-semibold text-white md:flex text-xss md:text-sm md:text-primary md:ml-4 md:mt-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              or call us +603 7733 4474 (Kuala Lumpur, GMT +8)
            </a>
          </div>
        </div>
      </div>

      <div className=" bg-gradient-to-b from-[hsla(215,66%,21%,1)] to-[hsla(215,59%,19%,1)] relative">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(215,66%,21%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[8vh] z-10 "
            }
          ></div>
        </div>

        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(215,59%,19%,0.2)] z-10 m-[-1px] after:h-[4vh] md:after:h-[12vh] z-0 "
            }
          ></div>
        </div>

        {breakpoints.md ? <IOTAccordion /> : <IotTab />}
      </div>

      <div className="relative bg-gradient-to-b from-[hsla(216,59%,30%,1)] to-[hsla(218,73%,41%,1)]">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tiltr +
              " after:bg-[hsla(215,59%,19%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[6vh] z-10"
            }
          ></div>
        </div>

        <div className="max-w-2xl px-8 pt-32 mx-auto text-white md:px-0 md:text-center">
          <h3 className="text-3xl font-bold tracking-wide text-white ">
            Automated Equipment Reports straight from assets & machinaries
          </h3>
          <p className="mt-4 leading-6 md:text-lg text-bluegray">
            KATSANA Orbital helps you to get rid of manual processes that are
            time consuming, resource intensive, and in some cases pose Health &
            Safety risks.
          </p>
        </div>

        <div className="relative z-20 flex flex-col-reverse max-w-6xl px-8 pt-4 pb-20 mx-auto xl:pb-0 md:pt-16 lg:flex-row xl:px-0">
          <div className="mt-16 lg:w-1/2 ">
            <h3 className="text-xl font-medium tracking-wide text-white ">
              Bespoke, Industry-specific Dashboard & Reports
            </h3>
            <p className="mt-4 text-sm leading-6 tracking-wide md:text-base text-bluegray">
              Reduce the amount of administrative tasks on the ground and
              workload in the office.
              <br />
              <br />
              KATSANA Orbital digitizes your ground operation and directly make
              it more efficient, reduces operation costs, and provides you with
              a single source of truth.
            </p>
            <div className="mt-8">
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    className="h-6 text-blue-200"
                  >
                    <g>
                      <g>
                        <path
                          d="M398.271,33.465h-35.965C358.925,14.471,342.302,0,322.352,0H189.648c-19.951,0-36.574,14.471-39.954,33.465h-35.965
			c-24.813,0-45,20.187-45,45V467c0,24.813,20.187,45,45,45h284.541c24.813,0,45-20.187,45-45V78.465
			C443.271,53.652,423.085,33.465,398.271,33.465z M179.055,40.594C179.055,34.752,183.807,30,189.648,30h132.703
			c5.842,0,10.594,4.752,10.594,10.594V71.97c0,5.841-4.752,10.594-10.594,10.594H189.648c-5.842,0-10.594-4.752-10.594-10.594
			V40.594z M398.271,482H113.729c-8.271,0-15-6.729-15-15V78.465c0-8.271,6.729-15,15-15h35.325v8.504
			c0,22.383,18.21,40.594,40.594,40.594h132.703c22.384,0,40.594-18.21,40.594-40.594v-8.504h35.325c8.271,0,15,6.729,15,15V467
			h0.001C413.271,475.271,406.542,482,398.271,482z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M362.841,194.666h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,194.666,362.841,194.666z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M224.544,143.078c-6.815-4.712-16.158-3.007-20.869,3.807l-33.528,48.49l-7.066-12.072
			c-4.186-7.15-13.374-9.552-20.523-5.367s-9.552,13.374-5.367,20.523l18.961,32.389c2.59,4.423,7.259,7.218,12.381,7.411
			c0.189,0.007,0.377,0.011,0.566,0.011c4.911,0,9.528-2.409,12.336-6.469l46.917-67.854
			C233.063,157.133,231.357,147.79,224.544,143.078z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M362.841,295.487h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,295.487,362.841,295.487z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M224.544,243.899c-6.815-4.713-16.158-3.007-20.869,3.807l-33.528,48.49l-7.066-12.072
			c-4.186-7.149-13.374-9.553-20.523-5.367c-7.149,4.185-9.552,13.374-5.367,20.523l18.961,32.389
			c2.59,4.423,7.259,7.218,12.381,7.411c0.189,0.007,0.377,0.011,0.566,0.011c4.911,0,9.528-2.409,12.336-6.469l46.917-67.854
			C233.063,257.954,231.357,248.611,224.544,243.899z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M362.841,244.702h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,244.702,362.841,244.702z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <p className="flex-1 ml-4 text-sm text-bluegray">
                  Receive automated reports with no human intervention
                </p>
              </div>
              <div className="flex items-center mt-4">
                <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    className="h-6 text-blue-200"
                  >
                    <g>
                      <g>
                        <path
                          d="M398.271,33.465h-35.965C358.925,14.471,342.302,0,322.352,0H189.648c-19.951,0-36.574,14.471-39.954,33.465h-35.965
			c-24.813,0-45,20.187-45,45V467c0,24.813,20.187,45,45,45h284.541c24.813,0,45-20.187,45-45V78.465
			C443.271,53.652,423.085,33.465,398.271,33.465z M179.055,40.594C179.055,34.752,183.807,30,189.648,30h132.703
			c5.842,0,10.594,4.752,10.594,10.594V71.97c0,5.841-4.752,10.594-10.594,10.594H189.648c-5.842,0-10.594-4.752-10.594-10.594
			V40.594z M398.271,482H113.729c-8.271,0-15-6.729-15-15V78.465c0-8.271,6.729-15,15-15h35.325v8.504
			c0,22.383,18.21,40.594,40.594,40.594h132.703c22.384,0,40.594-18.21,40.594-40.594v-8.504h35.325c8.271,0,15,6.729,15,15V467
			h0.001C413.271,475.271,406.542,482,398.271,482z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M362.841,194.666h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,194.666,362.841,194.666z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M224.544,143.078c-6.815-4.712-16.158-3.007-20.869,3.807l-33.528,48.49l-7.066-12.072
			c-4.186-7.15-13.374-9.552-20.523-5.367s-9.552,13.374-5.367,20.523l18.961,32.389c2.59,4.423,7.259,7.218,12.381,7.411
			c0.189,0.007,0.377,0.011,0.566,0.011c4.911,0,9.528-2.409,12.336-6.469l46.917-67.854
			C233.063,157.133,231.357,147.79,224.544,143.078z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M362.841,295.487h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,295.487,362.841,295.487z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M224.544,243.899c-6.815-4.713-16.158-3.007-20.869,3.807l-33.528,48.49l-7.066-12.072
			c-4.186-7.149-13.374-9.553-20.523-5.367c-7.149,4.185-9.552,13.374-5.367,20.523l18.961,32.389
			c2.59,4.423,7.259,7.218,12.381,7.411c0.189,0.007,0.377,0.011,0.566,0.011c4.911,0,9.528-2.409,12.336-6.469l46.917-67.854
			C233.063,257.954,231.357,248.611,224.544,243.899z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M362.841,244.702h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,244.702,362.841,244.702z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <p className="flex-1 ml-4 text-sm text-bluegray">
                  Integrate report & sensor reading through KATSANA API into
                  your existing systems
                </p>
              </div>
              <div className="flex items-center mt-4">
                <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    className="h-6 text-blue-200"
                  >
                    <g>
                      <g>
                        <path
                          d="M398.271,33.465h-35.965C358.925,14.471,342.302,0,322.352,0H189.648c-19.951,0-36.574,14.471-39.954,33.465h-35.965
			c-24.813,0-45,20.187-45,45V467c0,24.813,20.187,45,45,45h284.541c24.813,0,45-20.187,45-45V78.465
			C443.271,53.652,423.085,33.465,398.271,33.465z M179.055,40.594C179.055,34.752,183.807,30,189.648,30h132.703
			c5.842,0,10.594,4.752,10.594,10.594V71.97c0,5.841-4.752,10.594-10.594,10.594H189.648c-5.842,0-10.594-4.752-10.594-10.594
			V40.594z M398.271,482H113.729c-8.271,0-15-6.729-15-15V78.465c0-8.271,6.729-15,15-15h35.325v8.504
			c0,22.383,18.21,40.594,40.594,40.594h132.703c22.384,0,40.594-18.21,40.594-40.594v-8.504h35.325c8.271,0,15,6.729,15,15V467
			h0.001C413.271,475.271,406.542,482,398.271,482z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M362.841,194.666h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,194.666,362.841,194.666z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M224.544,143.078c-6.815-4.712-16.158-3.007-20.869,3.807l-33.528,48.49l-7.066-12.072
			c-4.186-7.15-13.374-9.552-20.523-5.367s-9.552,13.374-5.367,20.523l18.961,32.389c2.59,4.423,7.259,7.218,12.381,7.411
			c0.189,0.007,0.377,0.011,0.566,0.011c4.911,0,9.528-2.409,12.336-6.469l46.917-67.854
			C233.063,157.133,231.357,147.79,224.544,143.078z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M362.841,295.487h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,295.487,362.841,295.487z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M224.544,243.899c-6.815-4.713-16.158-3.007-20.869,3.807l-33.528,48.49l-7.066-12.072
			c-4.186-7.149-13.374-9.553-20.523-5.367c-7.149,4.185-9.552,13.374-5.367,20.523l18.961,32.389
			c2.59,4.423,7.259,7.218,12.381,7.411c0.189,0.007,0.377,0.011,0.566,0.011c4.911,0,9.528-2.409,12.336-6.469l46.917-67.854
			C233.063,257.954,231.357,248.611,224.544,243.899z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M362.841,244.702h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,244.702,362.841,244.702z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <p className="flex-1 ml-4 text-sm text-bluegray">
                  Custom reports to meet operational needs
                </p>
              </div>
              <div className="flex items-center mt-4">
                <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    className="h-6 text-blue-200"
                  >
                    <g>
                      <g>
                        <path
                          d="M398.271,33.465h-35.965C358.925,14.471,342.302,0,322.352,0H189.648c-19.951,0-36.574,14.471-39.954,33.465h-35.965
			c-24.813,0-45,20.187-45,45V467c0,24.813,20.187,45,45,45h284.541c24.813,0,45-20.187,45-45V78.465
			C443.271,53.652,423.085,33.465,398.271,33.465z M179.055,40.594C179.055,34.752,183.807,30,189.648,30h132.703
			c5.842,0,10.594,4.752,10.594,10.594V71.97c0,5.841-4.752,10.594-10.594,10.594H189.648c-5.842,0-10.594-4.752-10.594-10.594
			V40.594z M398.271,482H113.729c-8.271,0-15-6.729-15-15V78.465c0-8.271,6.729-15,15-15h35.325v8.504
			c0,22.383,18.21,40.594,40.594,40.594h132.703c22.384,0,40.594-18.21,40.594-40.594v-8.504h35.325c8.271,0,15,6.729,15,15V467
			h0.001C413.271,475.271,406.542,482,398.271,482z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M362.841,194.666h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,194.666,362.841,194.666z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M224.544,143.078c-6.815-4.712-16.158-3.007-20.869,3.807l-33.528,48.49l-7.066-12.072
			c-4.186-7.15-13.374-9.552-20.523-5.367s-9.552,13.374-5.367,20.523l18.961,32.389c2.59,4.423,7.259,7.218,12.381,7.411
			c0.189,0.007,0.377,0.011,0.566,0.011c4.911,0,9.528-2.409,12.336-6.469l46.917-67.854
			C233.063,157.133,231.357,147.79,224.544,143.078z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M362.841,295.487h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,295.487,362.841,295.487z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M224.544,243.899c-6.815-4.713-16.158-3.007-20.869,3.807l-33.528,48.49l-7.066-12.072
			c-4.186-7.149-13.374-9.553-20.523-5.367c-7.149,4.185-9.552,13.374-5.367,20.523l18.961,32.389
			c2.59,4.423,7.259,7.218,12.381,7.411c0.189,0.007,0.377,0.011,0.566,0.011c4.911,0,9.528-2.409,12.336-6.469l46.917-67.854
			C233.063,257.954,231.357,248.611,224.544,243.899z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M362.841,244.702h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,244.702,362.841,244.702z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <p className="flex-1 ml-4 text-sm text-bluegray">
                  Go paperless for a greener Environment
                </p>
              </div>
            </div>
          </div>

          <div className="relative flex mt-8 md:mt-0">
            <StaticImage
              alt="katsana"
              src="../../static/images/graphics/reports.png"
              objectFit="cover"
            />
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-b from-[hsla(204,26%,98%,1)] to-[hsla(204,26%,93%,1)] py-24 relative">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,98%,1)] m-[-1px] after:h-[4vh] md:after:h-[6vh] z-10"
            }
          ></div>
        </div>

        <div className="max-w-6xl px-8 mx-auto xl:px-0">
          <div className="max-w-2xl">
            <h3 className="text-3xl font-bold tracking-wide md:text-4xl text-bluetext ">
              Integrated Industrial Operations for <br />
              modern enterprises
            </h3>
            <p className="mt-4 leading-6 md:text-lg text-bluetext">
              Extract real-time data from your ground operations and connect
              them onto KATSANA Orbital, an integrated & highly secure data
              platform.
            </p>
            <a
              href="https://apps.katsana.com/get-quotation/"
              className="inline-flex px-5 py-2 mt-8 font-semibold text-white rounded-full md:self-start md:px-8 md:py-3 md:text-base leading-0 bg-primary"
            >
              Tell Us Your Needs
            </a>
          </div>
        </div>

        <div className="grid grid-cols-6 gap-8 px-8 py-16 mx-auto xl:px-0 max-w-7xl">
          <div className="col-span-6 md:col-span-2">
            <StaticImage
              alt="katsana"
              src="../../static/images/photos/industrialiot1.jpg"
              objectFit="contain"
              className=""
            />
            <p className="mt-4 text-xs text-bluetext">
              Automate day-to-day reporting - reduce resource and
              <br /> health & safety risk exposure
            </p>
          </div>
          <div className="col-span-6 md:col-span-2">
            <StaticImage
              alt="katsana"
              src="../../static/images/photos/industrialiot2.jpg"
              objectFit="contain"
              className=""
            />
            <p className="mt-4 text-xs text-bluetext">
              Get real-time data from existing and new industrial sensors.
            </p>
          </div>
          <div className="col-span-6 md:col-span-2 transform xl:translate-y-[-15vh]">
            <StaticImage
              alt="katsana"
              src="../../static/images/photos/industrialiot3.jpg"
              objectFit="contain"
              className=""
            />
            <p className="mt-4 text-xs text-bluetext">
              Make informed decisions based on actual data right at your
              fingertips.
            </p>

            <StaticImage
              alt="katsana"
              src="../../static/images/photos/industrialiot4.jpg"
              objectFit="contain"
              className="mt-16"
            />
            <p className="mt-4 text-xs text-bluetext">
              Extract data from KATSANA API to generate valuable insights.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-b py-24 relative from-[hsla(201,29%,81%,1)] to-[hsla(207,27%,94%,1)]">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(201,29%,81%,1)] m-[-1px] after:h-[4vh] md:after:h-[6vh] z-10"
            }
          ></div>
        </div>

        <div className="max-w-4xl px-8 mx-auto xl:text-center text-bluetext xl:px-0">
          <h3 className="text-4xl font-bold tracking-wide">
            Solving Industry’s Pain Points
          </h3>
          <p className="mt-4 leading-6 tracking-wide md:text-lg md:leading-8">
            KATSANA Orbital solutions are developed to meet diverse operational
            needs of the industry.
            <br />
            Each solution is a result of deep customer understanding through
            extensive research to identify problem statement and operational
            needs prior to solution roll-out.
            <br />
            <br />
            We take pride in our operation-driven solutions thanks to your
            input, our customers.
          </p>
        </div>
        <h5 className="px-8 mx-auto mt-12 font-medium md:text-lg md:mt-24 md:text-center text-bluetext xl:px-0">
          OPERATION-DRIVEN SOLUTIONING PROCESS
        </h5>
        <div className="grid max-w-6xl grid-cols-6 gap-8 px-8 pt-4 pb-20 mx-auto xl:px-0 md:pt-24 md:pb-36">
          <div className="col-span-6 md:col-span-2">
            <StaticImage
              alt="katsana"
              src="../../static/images/graphics/odprocess1.png"
              objectFit="contain"
              className="h-60"
            />
            <div className="flex mt-4">
              <div>
                <span className="inline-flex items-center justify-center w-8 h-8 mt-2 text-sm border rounded-full text-bluetext border-bluetext">
                  1
                </span>
              </div>
              <div className="ml-4 text-bluetext">
                <h5 className="font-medium">Assessment of Operational Needs</h5>
                <p className="mt-2 text-sm">
                  Experienced operational consultants interview the management
                  and ground operation formulate an understanding of the diverse
                  user needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-6 md:col-span-2">
            <StaticImage
              alt="katsana"
              src="../../static/images/graphics/odprocess2.png"
              objectFit="contain"
              className="h-60"
            />
            <div className="flex mt-4">
              <div>
                <span className="inline-flex items-center justify-center w-8 h-8 mt-2 text-sm border rounded-full text-bluetext border-bluetext">
                  2
                </span>
              </div>
              <div className="ml-4 text-bluetext">
                <h5 className="font-medium">Solution Discovery & Proposal</h5>
                <p className="mt-2 text-sm">
                  Through continuous engagements with the users, our operational
                  consultants propose solutions with cost-benefit considerations
                  and impact to the organisation.
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-6 md:col-span-2">
            <StaticImage
              alt="katsana"
              src="../../static/images/graphics/odprocess3.png"
              objectFit="contain"
              className="h-60"
            />
            <div className="flex mt-4">
              <div>
                <span className="inline-flex items-center justify-center w-8 h-8 mt-2 text-sm border rounded-full text-bluetext border-bluetext">
                  3
                </span>
              </div>
              <div className="ml-4 text-bluetext">
                <h5 className="font-medium">Solution Discovery</h5>
                <p className="mt-2 text-sm">
                  Solution delivery process begins. KATSANA continuously measure
                  the impact of the solution together with user feedback and
                  improvements to the platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Cta />
    </Layout>
  );
};

export default IOTPage;
